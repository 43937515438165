* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
}

:root {
  --golos-font: sans-serif;
}

html {
  scroll-behavior: smooth;
}

html,
body {
  color: white;
  margin: 0;
  padding: 0;
  height: 100%;
}

body {
  overflow-y: auto;
  scrollbar-width: auto;
  scrollbar-color: #4b4b4e;

  &::-webkit-scrollbar {
    width: 3px;
    height: 3px;
  }

  &::-webkit-scrollbar-track {
    background: #0d0d0d;
  }

  &::-webkit-scrollbar-thumb {
    background-color: #4b4b4e;
    border-radius: 1px;
    border: 0px none transparent;
  }
}

#__next {
  height: 100%;
}

/* 
  This selector hides the Crisp message button upon entering the page. 
  If you encounter any issues with Crisp, please double-check this selector. It may be prone to bugs
*/
#crisp-chatbox
  > div
  > a:not([rel='noopener noreferrer']):not([data-visible='true']) {
  display: none !important;
}

#crisp-chatbox a[data-visible='false'] {
  display: none !important;
}

#crisp-chatbox a[data-visible='true'] {
  display: block !important;
}

.toast-container {
  inset: 108px 16px 16px !important;

  @media (min-width: 992px) {
    inset: 145px 16px 16px !important;
  }
}
